<script lang="ts" setup>
import { useTemplateRef } from "vue";
import { WuxToastElementProps } from "./WuxToast.core";
import WuxToastController from "./WuxToastController.vue";

const toastController = useTemplateRef("toastController");

const showSuccess = (props: WuxToastElementProps): void => toastController.value?.showSuccess(props);

defineExpose({ showSuccess });
</script>

<template>
    <WuxToastController ref="toastController" class="hidden-print" />
</template>
