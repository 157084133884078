type ToRat = {
    (v: number): Rational;
    (v: bigint): Rational;
    (v: string): Rational | undefined;
};

/**
 * Returns the absolute of a bigint, i.e. removing the sign.
 */
const abs = (x: bigint): bigint => (x < 0n ? -x : x);

/**
 * Calculate the greatest common divisor for two bigints.
 * Adjusted from: https://www.w3resource.com/javascript-exercises/javascript-math-exercise-8.php
 */
const _gcd = (x: bigint, y: bigint) => {
    let _x = abs(x);
    let _y = abs(y);
    while (_y) {
        const t = _y;
        _y = _x % _y;
        _x = t;
    }
    return _x;
};

const toSafeBigInt = (x: string): bigint | undefined => {
    try {
        return BigInt(x);
    } catch (_e) {
        return undefined;
    }
};

export const asRat = (r: Rational | bigint) => (typeof r === "bigint" ? new Rational(r) : r);

// number of digits after comma
// only the first 16 digits after comma can be accurate for JS number, the rest is producing just some unexpected values
const DEFAULT_PRECISION = 16;

export class Rational {
    readonly numerator: bigint;
    readonly denominator: bigint;
    // required to serialize a Rational compatible for GoogleDecimal proto messages until migration was done
    // to prevent its direct usage, it will have the type "unknown"
    readonly value: unknown;

    static ONE = new Rational(1n, 1n);
    static ZERO = new Rational(0n, 1n);

    static toRat: ToRat = (v) => {
        // we need to cast to any because TS gets confused with the overloaded function otherwise
        if (typeof v === "number") return Rational.toRat(v.toFixed(DEFAULT_PRECISION)) as any;
        if (typeof v === "bigint") return Rational.toRat(v.toString()) as any;

        const [integral, fraction = ""] = v.split(".");
        const numerator = toSafeBigInt(integral + fraction);
        const denominator = toSafeBigInt("1" + "0".repeat(fraction.length));

        if (numerator === undefined || denominator === undefined) return undefined;

        return new Rational(numerator, denominator);
    };

    static sum = (...vals: (Rational | bigint)[]): Rational => {
        const _sum = vals.reduce<Rational>((last, next) => {
            const { numerator: n1, denominator: d1 } = last;
            const { numerator: n2, denominator: d2 } = asRat(next);
            return new Rational(n1 * d2 + n2 * d1, d1 * d2);
        }, Rational.ZERO);

        return _sum;
    };

    static multiply = (...vals: (Rational | bigint)[]): Rational => {
        const p = vals.reduce<Rational>((last, next) => {
            const { numerator: n1, denominator: d1 } = last;
            const { numerator: n2, denominator: d2 } = asRat(next);
            return new Rational(n1 * n2, d1 * d2);
        }, Rational.ONE);

        return p;
    };

    constructor(numerator: bigint, denominator: bigint = 1n) {
        if (denominator === 0n) throw new Error("Illegal rational instantiation");
        // normalize the stored values
        const n = denominator < 0 ? -numerator : numerator;
        const d = denominator < 0 ? -denominator : denominator;
        const gcd = _gcd(n, d);
        this.numerator = n / gcd;
        this.denominator = d / gcd;
        this.value = this.toString().replace(/\.?0+$/, "");
    }

    equals(other: Rational): boolean {
        return this.numerator === other.numerator && this.denominator === other.denominator;
    }

    /**
     * This method returns always in the same format as Number.prototype.toFixed.
     * BUT: It rounds rather mathematically correct like Math.round.
     *      E.g. (-4.5).toFixed(0) === "-5" but Math.round(-4.5) === new Rational(-9n, 2n).toFixed(0) === "-4"
     * To format a number for user presentation this method is used and wrapped by the useMsgFormatter().n function.
     */
    toFixed(precision: number): string {
        const usedPrecision = precision <= 0 ? 0 : Math.floor(precision);
        // adding 2 to do the precision to do the mathematical rounding
        const multiplicator = BigInt("1" + "0".repeat(usedPrecision + 2));
        const result = (this.numerator * multiplicator) / this.denominator;
        const integral = result / multiplicator;
        const fraction = abs(result % multiplicator);
        const isNeg = this.isNegative();
        const sign = isNeg ? "-" : "";
        const cutoffFraction = fraction % 100n;
        const rounding = cutoffFraction > 50n || (cutoffFraction === 50n && !isNeg) ? 1n : 0n;
        if (usedPrecision === 0) {
            return `${sign}${abs(integral + (isNeg ? -rounding : rounding))}`;
        }
        const fractionPart = (fraction / 100n + rounding).toString().padStart(usedPrecision, "0");
        const integralPart = abs(integral) + (fractionPart.length > usedPrecision ? 1n : 0n);
        const withoutSign = `${integralPart}.${fractionPart.slice(-usedPrecision)}`;
        return `${/^0\.0+$/.test(withoutSign) ? "" : sign}${withoutSign}`;
    }

    // Some teams using toString and expected to have the rational value.
    // Override toString for rational to use toFixed
    toString() {
        return this.toFixed(DEFAULT_PRECISION);
    }

    negate(): Rational {
        return new Rational(-this.numerator, this.denominator);
    }

    absolute(): Rational {
        // make sure to return immutable copy
        return this.isNegative() ? this.negate() : new Rational(this.numerator, this.denominator);
    }

    isPositive(): boolean {
        return this.numerator > 0n;
    }

    isNegative(): boolean {
        return this.numerator < 0n;
    }

    isInt(): boolean {
        return this.denominator === 1n;
    }

    compareTo(other: Rational | bigint): -1 | 0 | 1 {
        const otherRat = asRat(other);
        const diff = Rational.sum(this, otherRat.negate());
        if (diff.numerator === 0n) return 0;
        return diff.isPositive() ? 1 : -1;
    }

    lessThan(other: Rational | bigint): boolean {
        return this.compareTo(other) === -1;
    }

    greaterThan(other: Rational | bigint): boolean {
        return this.compareTo(other) === 1;
    }

    /**
     * Uses a different interface than "multiply" and "add", because it is a non-commutative operation.
     */
    divideBy(divisor: Rational | bigint): Rational {
        const divisorRat = asRat(divisor);
        return Rational.multiply(this, new Rational(divisorRat.denominator, divisorRat.numerator));
    }

    ceil(): bigint {
        if (this.isInt()) return this.numerator;
        const div = this.numerator / this.denominator;
        return div + (this.isNegative() ? 0n : 1n);
    }

    floor(): bigint {
        if (this.isInt()) return this.numerator;
        const div = this.numerator / this.denominator;
        return div - (this.isNegative() ? 1n : 0n);
    }

    /**
     * truncate returns the integer part of a rational number r, truncating towards zero.
     * It has the same behaviour as floor() for positive rationals and ceil() for negative rationals.
     */
    truncate(): bigint {
        return this.numerator / this.denominator;
    }

    // toJSON(_?: any): string  function was removed in this PR 487416, because it was causing the rational variable to be sent to proto as string, and proto were not able to read it in the backend
    // example of how the data was sent is {\"numerator\":\"21\",\"denominator\":\"1\"}
}
