import { globalAbortSignal } from "./globalAbortController";

export const MAX_TIMEOUT_FETCH = 60_000; // 1 min
export const MAX_TIMEOUT_BLOB_STORAGE = 180_000; // 3 min
export const REASON_TIMEOUT = "TIMED OUT";
export const REASON_OFFLINE = "OFFLINE";

// related:
// - https://github.com/whatwg/fetch/issues/905
// - https://github.com/whatwg/dom/issues/920#issuecomment-726203604
const mergeAbortControllerSignals = (...signals: (AbortSignal | undefined)[]) => {
    const ctrl = new AbortController();

    signals.forEach((signal) => {
        if (!signal) return;
        signal.addEventListener("abort", () => ctrl.abort(signal.reason), {
            signal: ctrl.signal,
            once: true,
        });
    });

    return ctrl;
};

export const getTimeoutController = (
    timeLimit: number,
    signal?: AbortSignal,
): { signal: AbortSignal; clearTimeout: () => void } => {
    // early escape (if already aborted, just forward that signal)
    if (signal?.aborted) return { signal, clearTimeout: () => undefined };

    const ctrl = mergeAbortControllerSignals(globalAbortSignal, signal);

    const timeout = setTimeout(() => ctrl.abort(REASON_TIMEOUT), timeLimit);
    return { signal: ctrl.signal, clearTimeout: () => clearTimeout(timeout) };
};
