<script lang="ts" setup>
import { computed, useTemplateRef } from "vue";
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { type RawOrMsg } from "../../types";
import { Shortcut } from "../../utils/shortcuts";
import { getGroupedBy } from "../WuxLayout/layout.core";
import WuxOptionButton from "../WuxOptionButton/WuxOptionButton.vue";
import type { TreeListItem } from "../WuxTreeList/WuxTreeList.core";

// choose raw string "label" xor translated label using "labelMsg"
type TopBarDropdownOption = {
    value: string;
    categoryId?: string;
    shortLabel?: string;
    shortcut?: Shortcut;
    href?: string;
    disabledMsg?: MessageOrId;
} & RawOrMsg<"label">;

type TopBarCategory = { id: string; labelMsg: MessageOrId; shortcut?: Shortcut; categories?: TopBarCategory[] };

const props = defineProps<{
    /**
     * Will be displayed when no value is specified.
     */
    labelMsg: MessageOrId;
    options: TopBarDropdownOption[];
    categories?: TopBarCategory[];
    value?: string;
    isDisabled?: boolean;
    flipLabels?: boolean;
    /**
     * Needs to be defined initially, can not be changed afterwards.
     */
    shortcut?: Shortcut;
}>();

const emit = defineEmits<{
    (e: "change", value: string, metaKey: boolean): void;
    (e: "open"): void;
}>();

// Exposed function to externally close the dropdown. This is used inside the BaseLayout to make the drop downs exclusive.
const buttonRef = useTemplateRef("buttonRef");
defineExpose({ close: () => buttonRef.value?.close() });

const { optM, rawM } = useMsgFormatter();

const currentOption = computed(() => props.options.find(({ value }) => value === props.value));
const label = computed(
    () =>
        currentOption.value?.shortLabel ??
        rawM(currentOption.value?.labelMsg, currentOption.value?.label) ??
        optM(props.labelMsg),
);

const getNestedItems = (category: TopBarCategory, grouped: [string, TreeListItem[]][]): TreeListItem | undefined => {
    const allNestedItems =
        category.categories?.map((nestedCategory) => getNestedItems(nestedCategory, grouped)).filter(Boolean) ?? [];

    const items = grouped.find((item) => category.id === item[0])?.[1];

    const allItems = [...(items ?? []), ...allNestedItems];

    return items
        ? {
              items: allItems,
              shortcut: category.shortcut,
              label: optM(category.labelMsg) ?? "",
              isSelected: props.value
                  ? allItems.some(
                        (item) =>
                            item.isSelected || (rawM(item.labelMsg, item.label) || "").includes(props.value ?? ""),
                    )
                  : false,
          }
        : undefined;
};

const flip = (labels: string[]) => (props.flipLabels ? labels.toReversed() : labels);

const items = computed<TreeListItem[]>(() => {
    const { notGrouped, grouped } = getGroupedBy(
        "categoryId",
        props.options.map(({ labelMsg, label, shortLabel, value, categoryId, shortcut, href, disabledMsg }) => ({
            shortcut,
            categoryId,
            label: flip([rawM(labelMsg, label), shortLabel].filter(Boolean))[0],
            description: flip([rawM(labelMsg, label), shortLabel].filter(Boolean))[1],
            onClick: (e?: MouseEvent | KeyboardEvent) => emit("change", value, !!e?.metaKey || !!e?.ctrlKey),
            href,
            isSelected: props.value ? value.includes(props.value) : undefined,
            disabledMsg,
        })),
    );
    const groupedItems: TreeListItem[] = [];
    props.categories?.forEach((c) => {
        const item = getNestedItems(c, grouped);
        item && groupedItems.push(item);
    });
    return [...notGrouped, ...groupedItems];
});
</script>

<template>
    <WuxOptionButton
        v-if="props.options.length"
        ref="buttonRef"
        class="wux-top-bar-dropdown"
        :class="{ 'wux-top-bar-dropdown--disabled': props.isDisabled }"
        isPrimary
        isDense
        :isDisabled="props.isDisabled"
        :label
        :options="items"
        :shortcut="props.shortcut"
        @open="emit('open')"
    />
</template>

<style lang="scss">
.wux-top-bar-dropdown {
    .wux-option-button__button {
        border-radius: 0;
        text-transform: none;
        --wux-button-font-size: 14px;
        --wux-button-bg-color: transparent;
        --wux-button-bg-hover-color: var(--wawi-color-white-overlay);
        min-height: 2.5rem;
        gap: 0.1rem;

        &.wux-button--dense:has(.wux-icon):has(.wux-button__label) {
            padding-left: 0.5rem;
        }

        &:hover {
            background-color: var(--wawi-color-white-overlay);
        }
    }

    &:focus-within {
        .wux-option-button__button {
            outline: var(--focus-visible-outline-width--regular) solid var(--wawi-color-grayscale-white);
            outline-offset: var(--focus-visible-outline-offset--regular);
        }
    }

    &--disabled {
        opacity: 0.7;
    }
}
</style>
