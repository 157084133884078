<script lang="ts" setup>
import { computed } from "vue";
import { __, DeadEndAction, MessageOrId, WuxErrorPage } from "@ui/components";
import { UnauthorizedReason } from "../BaseLayout.core";
import { Datadog } from "../datadog";

const props = defineProps<{
    reason: UnauthorizedReason;
}>();

const contentMsg = computed(() => {
    switch (props.reason) {
        case UnauthorizedReason.INVALID_LOCATION:
            return __("ui.libs.skeleton.UnauthorizedPage.invalid-location");
        case UnauthorizedReason.UNAUTHORIZED_MODULE:
            return __("ui.libs.skeleton.UnauthorizedPage.unauthorized-module");
        case UnauthorizedReason.INACTIVE_MODULE:
            return __("ui.libs.skeleton.UnauthorizedPage.inactive-module");
        case UnauthorizedReason.DISABLED_SETTING:
            return __("ui.libs.skeleton.UnauthorizedPage.disabled-setting");
        case UnauthorizedReason.INACTIVE_FEATURE:
            return __("ui.libs.skeleton.UnauthorizedPage.inactive-feature");
        case UnauthorizedReason.RESTRICTED_COUNTRY:
            return __("ui.libs.skeleton.UnauthorizedPage.restricted-country");
        case UnauthorizedReason.MISSING_POLICY:
            return __("ui.libs.skeleton.UnauthorizedPage.missing-policy");
        default:
            return __("ui.libs.skeleton.UnauthorizedPage.content");
    }
});

const footNoteMsg: MessageOrId = {
    id: __("ui.libs.skeleton.UnauthorizedPage.session-id-hint"),
    values: { session: Datadog.getSessionID() },
};

const secondary: DeadEndAction = {
    labelMsg: __("ui.libs.skeleton.UnauthorizedPage.view-siam-roles"),
    onClick: () => window.location.replace("/module/launchpad/siam-roles"),
};

const showLinkToRoles = computed(() =>
    [
        UnauthorizedReason.INVALID_LOCATION,
        UnauthorizedReason.UNAUTHORIZED_MODULE,
        UnauthorizedReason.MISSING_POLICY,
    ].includes(props.reason),
);
</script>

<template>
    <WuxErrorPage
        :titleMsg="__('ui.libs.skeleton.UnauthorizedPage.title')"
        :contentMsg
        :footNoteMsg
        :secondary="showLinkToRoles ? secondary : undefined"
    />
</template>
