/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export enum Domain {
    DOMAIN_UNKNOWN = "DOMAIN_UNKNOWN",
    DOMAIN_WAM = "DOMAIN_WAM",
    DOMAIN_IVM = "DOMAIN_IVM",
    DOMAIN_MD = "DOMAIN_MD",
    DOMAIN_SFO = "DOMAIN_SFO",
    DOMAIN_GIN = "DOMAIN_GIN",
    DOMAIN_KEP = "DOMAIN_KEP",
    DOMAIN_INV = "DOMAIN_INV",
    DOMAIN_DIS = "DOMAIN_DIS",
    DOMAIN_LEGACY = "DOMAIN_LEGACY",
    DOMAIN_YND = "DOMAIN_YND",
    DOMAIN_TIL = "DOMAIN_TIL",
    DOMAIN_GOUT = "DOMAIN_GOUT",
    DOMAIN_SDIS = "DOMAIN_SDIS",
    DOMAIN_STD = "DOMAIN_STD",
    DOMAIN_FDI = "DOMAIN_FDI",
    DOMAIN_STOCK = "DOMAIN_STOCK",
    DOMAIN_SINV = "DOMAIN_SINV",
    DOMAIN_PROM = "DOMAIN_PROM",
    DOMAIN_MSC = "DOMAIN_MSC",
    DOMAIN_GTRC = "DOMAIN_GTRC",
    DOMAIN_DDEL = "DOMAIN_DDEL",
    DOMAIN_ACCOUNT = "DOMAIN_ACCOUNT",
    DOMAIN_PCF = "DOMAIN_PCF",
    DOMAIN_ALMA = "DOMAIN_ALMA",
}

export const DomainSchema = z.nativeEnum(Domain).catch(Domain.DOMAIN_UNKNOWN);
