<script lang="ts" setup>
import { useTemplateRef } from "vue";
import { __, Icon, Icons, MessageOrId, RawOrMsg, Shortcut, WuxOptionButton } from "@ui/components";

export type NotificationMenuButton = {
    icon?: Icon;
    shortcut?: Shortcut;
    descriptionMsg?: MessageOrId;
    description?: string;
    href?: string;
    onClick?: (event?: MouseEvent | KeyboardEvent) => void | Promise<void>;
} & RawOrMsg<"label">;

export type NotificationMenuProps = {
    notifications: NotificationMenuButton[];
    active?: boolean;
};

const props = defineProps<{ active?: boolean; shortcut: Shortcut; notifications: NotificationMenuButton[] }>();
const emit = defineEmits<{ open: [] }>();

const optionButton = useTemplateRef("optionButton");
defineExpose({ close: () => optionButton.value?.close() });
</script>

<template>
    <WuxOptionButton
        v-if="notifications.length > 0"
        ref="optionButton"
        class="notification-menu"
        :class="{ 'notification-menu--active': props.active }"
        isPrimary
        isDense
        :icon="props.active ? Icons.bell_ring : Icons.bell"
        :options="notifications"
        :shortcut="props.shortcut"
        @open="emit('open')"
    />
</template>

<style lang="scss">
.notification-menu {
    .wux-option-button__button {
        border-radius: 0;
        --wux-button-bg-color: transparent;
        --wux-button-bg-hover-color: var(--wawi-color-white-overlay);
        min-height: 2.5rem;
        min-width: 2.5rem;

        &:hover {
            background-color: var(--wawi-color-white-overlay);
        }
    }

    &:focus-within {
        .wux-option-button__button {
            outline: var(--focus-visible-outline-width--regular) solid var(--wawi-color-grayscale-white);
            outline-offset: var(--focus-visible-outline-offset--regular);
        }
    }

    &--active {
        .wux-option-button__button {
            position: relative;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 0.3rem;
                right: 0.3rem;
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                background-color: var(--wawi-color-red-500);
                border: 2px solid var(--wawi-color-grayscale-white);
            }
        }
    }
}

// if the background of the top-bar is also red, make the bubble blue
.wux-top-bar[style*="--layout-background-override: var(--wawi-color-red-500)"] {
    .notification-menu--active .wux-option-button__button:after {
        background-color: var(--wawi-color-primary-500);
    }
}
</style>
