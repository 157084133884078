<script setup lang="ts">
import { CSSProperties, onBeforeUnmount, ref, useTemplateRef, watch } from "vue";
import { calculatePopoverCoords } from "./WuxPopover.core";

const popover = useTemplateRef("popover");

const props = defineProps<{
    alignment: "left" | "right";
    isOpen: boolean;
    isOpenedOnClick?: boolean;
    targetRect?: DOMRectReadOnly;
    offset?: number;
}>();

const emit = defineEmits<{ (event: "update:isOpen", isOpen: boolean): void }>();

const popoverRect = ref<DOMRectReadOnly>();
const handleResize: ResizeObserverCallback = ([resize]) => (popoverRect.value = resize.target.getBoundingClientRect());
const resizeObserver = new ResizeObserver(handleResize);

const actualStyle = ref<CSSProperties>({ opacity: 0 });

watch(
    () => {
        if (!props.isOpen || !props.targetRect || !popoverRect.value) {
            actualStyle.value = { opacity: 0 };
            return;
        }
        return calculatePopoverCoords(props.alignment, props.targetRect, popoverRect.value, props.offset);
    },
    // Delay setting the style for a frame so that the `ResizeObserver` doesn't think it's stuck in a loop
    (newCoords) => setTimeout(() => (actualStyle.value = newCoords ?? { opacity: 0 }), 0),
    { deep: true },
);

const openPopover = () => popover.value?.showPopover();
const hidePopover = () => popover.value?.hidePopover();

watch(
    () => props.isOpen,
    () => {
        try {
            if (props.isOpen) {
                resizeObserver.observe(popover.value!);
                openPopover();
            } else {
                hidePopover();
                resizeObserver.unobserve(popover.value!);
            }
        } catch (e) {
            /* empty */
        }
    },
);

onBeforeUnmount(() => {
    resizeObserver.disconnect();
});
</script>

<template>
    <div
        ref="popover"
        class="wux-popover"
        :style="actualStyle"
        :is-open
        @toggle="emit('update:isOpen', $event.newState === 'open')"
        :popover="props.isOpenedOnClick ? 'auto' : 'manual'"
    >
        <slot v-if="props.isOpen"></slot>
    </div>
</template>

<style lang="scss">
.wux-popover {
    position: absolute;
    visibility: hidden;
    margin: 0;
    padding: 0;
    border: none;
    pointer-events: none;
    background: transparent;

    &:popover-open {
        visibility: visible;
        pointer-events: auto;
    }
}
</style>
