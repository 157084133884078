/**
 *
 * This code is used inside the application to handle the scenario where a user is redirected
 * to a new URL or closes the browser, which might cause ongoing fetch requests to fail.
 * To prevent the "failed to fetch" errors, we abort all ongoing requests before redirecting / closing.
 *
 * Consumers of this abort controller can detect if a fetch was aborted in this manner
 * by checking the reason provided when the request is aborted.
 *
 **/
const abortController = new AbortController();
export const globalAbortSignal = abortController.signal;
export const REASON_BROWSER_UNLOAD = "BROWSER_UNLOAD";

export const abortAllRequests = () => {
    abortController.abort(REASON_BROWSER_UNLOAD);
};
