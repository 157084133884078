<script lang="ts" setup>
import { computed, toRef, useTemplateRef } from "vue";
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { type Icon } from "../../icons";
import { DatadogActions } from "../../utils";
import { Shortcut, ShortcutUtil } from "../../utils/shortcuts";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import WuxSpinner from "../WuxSpinner/WuxSpinner.vue";

export type ButtonVariant = "default" | "text" | "outline";
export type WuxButtonProps = {
    /**
     * Shows a disabled button with a loading indicator animation
     */
    isLoading?: boolean;
    /**
     * Shows a disabled button, the click action will not be executed
     *
     * @deprecated This property will be removed. You should use the disabledMsg property to inform the user, why the field is disabled.
     */
    isDisabled?: boolean;
    /**
     * Please use it when you disable the button to explain WHY it is disabled
     */
    disabledMsg?: MessageOrId;
    /**
     * Shows a smaller button in the same height as an input
     */
    isDense?: boolean;
    /**
     * mandatory HTML attribute, defaults to 'button'
     */
    type?: "button" | "submit" | "reset";
    /**
     * Sets the style variant of a button (filled/default, outline, only text)
     */
    variant?: ButtonVariant;
    /**
     * If you don't provide a `labelMsg` or `label`, you'll get a square icon button
     */
    icon?: Icon;
    /**
     * Displays a blue, primary button. Please use only one primary button per page for the main action on it.
     */
    isPrimary?: boolean;
    /**
     * Tooltip message.
     */
    titleMsg?: MessageOrId;
    /**
     * If provided, this shortcut will make the button emit a click event
     */
    shortcut?: Shortcut;
    /**
     * Message for label
     */
    labelMsg?: MessageOrId;
    /**
     * Text for label
     */
    label?: string;
};

const props = withDefaults(defineProps<WuxButtonProps>(), { variant: "default", type: "button" });

const emit = defineEmits<{
    /**
     * `MouseEvent`
     */
    (e: "click", event: MouseEvent | KeyboardEvent): void;
}>();

const { rawM, optM } = useMsgFormatter();

const onClick = (e: MouseEvent | KeyboardEvent) => {
    emit("click", e);
};
const btn = useTemplateRef("btn");

const focus = () => {
    btn?.value?.focus();
};

defineExpose({
    focus,
});

const typeClass = computed(() => `wux-button--${props.isPrimary ? "primary" : "secondary"}-${props.variant}`);

ShortcutUtil.useGlobal(toRef(props, "shortcut"), (e) => {
    e.preventDefault();
    // To simulate the same behavior as normal click
    btn.value?.focus();
    btn.value?.click();
});
</script>

<template>
    <button
        ref="btn"
        class="wux-button"
        :class="[typeClass, { 'wux-button--loading': props.isLoading, 'wux-button--dense': props.isDense }]"
        :type="props.type"
        :title="optM(props.disabledMsg) || optM(props.titleMsg)"
        :disabled="!!props.disabledMsg || props.isDisabled || props.isLoading"
        :data-dd-action="DatadogActions.getName(props.labelMsg || props.label || props.titleMsg)"
        @click="onClick"
    >
        <WuxIcon
            v-if="props.icon"
            class="wux-button__icon"
            :src="props.icon"
            :size="isDense ? 18 : 24"
            :title="optM(props.disabledMsg) || optM(props.titleMsg)"
        />
        <span v-if="rawM(props.labelMsg, props.label)" class="wux-button__label">
            {{ rawM(props.labelMsg, props.label) }}
        </span>
        <slot name="iconRight" />
        <WuxSpinner v-if="props.isLoading" />
    </button>
</template>
<style lang="scss">
@use "../../../assets/styles/mixins.scss" as mixins;
.wux-button {
    $border-width: 1px;

    --_wux-button-color: var(--wux-button-color, var(--wawi-color-grayscale-white));
    --_wux-button-hover-color: var(--wux-button-hover-color, var(--_wux-button-color));
    --_wux-button-bg-color: var(--wux-button-bg-color, var(--wawi-color-primary-500));
    --_wux-button-bg-hover-color: var(--wux-button-bg-hover-color, var(--wawi-color-primary-600));
    --_wux-button-border-color: transparent;
    --_wux-button-font-size: var(--wux-button-font-size, 16px);
    --_wux-button-label-padding: 0rem;
    --_wux-button-padding: 0.5rem;

    position: relative;
    color: var(--_wux-button-color);
    background-color: var(--_wux-button-bg-color);
    padding: 0 10px;
    font-family: var(--wawi-default-font-family);
    font-weight: 600;
    font-size: var(--_wux-button-font-size);
    text-transform: uppercase;
    border: $border-width solid var(--_wux-button-border-color);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: filter 0.05s linear;

    &:has(.wux-icon) {
        padding: 0 8px;
    }

    &:disabled {
        cursor: default; // disabled buttons should not have a "hand" cursor
        --_wux-button-bg-color: var(--wawi-color-primary-200);
    }

    &:not(:disabled) {
        &:active {
            filter: brightness(95%);
        }

        &:hover {
            color: var(--_wux-button-hover-color);
            background-color: var(--_wux-button-bg-hover-color);
        }
    }

    &:focus {
        outline: none;
    }

    @include mixins.focus-outline();

    &:has(.wux-button__label) {
        --_wux-button-label-padding: 0.5rem;
    }

    &--primary-default {
        &:active:not(:disabled) {
            filter: brightness(95%);
        }
    }

    &--secondary-default {
        --_wux-button-color: var(--wux-button-color, var(--wawi-color-grayscale-white));
        --_wux-button-bg-color: var(--wux-button-bg-color, var(--wawi-color-neutral-600));
        --_wux-button-bg-hover-color: var(--wux-button-bg-hover-color, var(--wawi-color-neutral-900));

        &:disabled {
            --_wux-button-color: var(--wawi-color-neutral-500);
            --_wux-button-bg-color: var(--wawi-color-neutral-200);
            --_wux-button-border-color: var(--wawi-color-neutral-200);
        }

        &:active:not(:disabled) {
            filter: brightness(90%);
        }
    }

    &--primary-outline {
        --_wux-button-color: var(--wux-button-color, var(--wawi-color-primary-500));
        --_wux-button-bg-color: var(--wux-button-bg-color, transparent);
        --_wux-button-bg-hover-color: var(--wux-button-bg-hover-color, var(--wawi-color-neutral-200));
        --_wux-button-border-color: var(--wawi-color-primary-500);

        &:disabled {
            --_wux-button-color: var(--wawi-color-primary-200);
            --_wux-button-bg-color: transparent;
            --_wux-button-border-color: var(--wawi-color-primary-200);
        }
    }

    &--secondary-outline {
        --_wux-button-color: var(--wux-button-color, var(--wawi-color-neutral-900));
        --_wux-button-bg-color: var(--wux-button-bg-color, transparent);
        --_wux-button-bg-hover-color: var(--wux-button-bg-hover-color, var(--wawi-color-neutral-200));
        --_wux-button-border-color: var(--wawi-color-neutral-900);

        &:disabled {
            --_wux-button-color: var(--wawi-color-neutral-500);
            --_wux-button-border-color: var(--wawi-color-neutral-500);
            --_wux-button-bg-color: transparent;
        }
    }

    &--primary-text {
        --_wux-button-color: var(--wux-button-color, var(--wawi-color-primary-500));
        --_wux-button-font-size: 16x;
        --_wux-button-bg-color: var(--wux-button-bg-color, transparent);
        --_wux-button-bg-hover-color: var(--wux-button-bg-hover-color, var(--wawi-color-neutral-200));

        &:has(.wux-button__label) {
            --_wux-button-padding: 0.25rem;
        }

        &:disabled {
            --_wux-button-color: var(--wawi-color-primary-200);
            --_wux-button-bg-color: transparent;
        }
    }

    &--secondary-text {
        --_wux-button-font-size: 16x;
        --_wux-button-color: var(--wux-button-color, var(--wawi-color-neutral-900));
        --_wux-button-bg-color: var(--wux-button-bg-color, transparent);
        --_wux-button-bg-hover-color: var(--wux-button-bg-hover-color, var(--wawi-color-neutral-200));

        &:has(.wux-button__label) {
            --_wux-button-padding: 0.25rem;
        }

        &:disabled {
            --_wux-button-color: var(--wawi-color-neutral-500);
            --_wux-button-bg-color: transparent;
        }
    }

    &--dense {
        --_wux-button-label-padding: 0.2rem;
        padding: 0 calc(var(--_wux-button-label-padding) - $border-width);
        min-height: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        overflow: hidden;
        font-size: 13px;
        gap: 0.25rem;
        .wux-button__label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &:has(.wux-icon) {
            padding: 0 3px;
            &:has(.wux-button__label) {
                padding: 0 5px 0 3px;
            }
        }
    }

    &__label {
        user-select: none;
    }

    .wux-icon {
        flex-shrink: 0;
    }

    .wux-spinner {
        position: absolute;
        pointer-events: none;
    }
}
</style>
