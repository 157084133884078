<script lang="ts" setup>
import { onMounted, useTemplateRef } from "vue";
import { __ } from "../../i18n";
import { addToast, toastStore, WuxToastElementProps } from "./WuxToast.core";
import WuxToastElement from "./WuxToastElement.vue";

const toastPopover = useTemplateRef("toastPopover");

const showSuccess = (props: WuxToastElementProps): void => {
    addToast(props);
};

onMounted(() => toastPopover.value?.showPopover());

defineExpose({ showSuccess });
</script>

<template>
    <div ref="toastPopover" class="wux-toast-controller" :is-open="true" popover="manual">
        <TransitionGroup name="wux-toast-controller__transition">
            <WuxToastElement
                v-for="toast in toastStore.layers"
                :key="toast.id"
                class="wux-toast-controller__element"
                :headerMsg="toast.headerMsg"
                :contentMsg="toast.contentMsg"
                @close="toast.onClose"
            />
        </TransitionGroup>
    </div>
</template>
<style lang="scss">
.wux-toast-controller {
    --wux-toast-controller-transition-time: 0.15s;
    --wux-toast-controller-move-transition-time: 0.167s; // the move transition must be a little slower to avoid jumping effects when adding multiple toasts

    width: 100%;
    border: none;
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    &__transition {
        &-enter-active,
        &-leave-active {
            transition: all var(--wux-toast-controller-transition-time) linear;
            pointer-events: none;
        }

        &-move {
            transition: all var(--wux-toast-controller-move-transition-time) linear;
            pointer-events: none;
        }

        &-enter-from {
            transform: translateY(100%);
        }

        &-leave-to {
            opacity: 0;
        }
    }
}
body:has(.wux-bottom-bar--page) {
    .wux-toast-controller {
        margin-bottom: var(--wux-bottom-bar-height);
    }
}
</style>
